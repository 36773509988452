import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Grid, Modal, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import { X } from "react-feather";
import MembercodeVerification from "../DeleteMember";
import members from "../../services/membersList";
import LoadingSpinner from "../loadingSpinner";
import { maskEmail, validateEmail } from "../../common/common";
import userService from "../../services/user";
import UserAccessCodeVerificationBase from "../userAccessCodeVerification";

const ConfirmSubscriptionCancellationButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

const CancelButton = styled(Button)({
  height: "44px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  background: "#FFFFFF",
  border: "1px solid #1A2842",
  boxSizing: "border-box",
  color: "#1A2842",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#FFFFFF",
  },
  "&:focus": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});

export default function UserAccess(props) {
  const user = useSelector((state) => state.auth.user);

  const [isLoading, setIsLoading] = useState(true);
  const [emailIds, setEmailIds] = useState([]);
  const [email, setemailId] = useState("");
  const [deleteMail, setDeleteMail] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [deletemodalOpen, setDeleteModalOpen] = useState(false);
  const [verifyPrimaryUserModal, setVerifyPrimaryUserModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const smallDeviceMatch = !useMediaQuery("(min-width:656px)");

  const useStyles = makeStyles((theme) => ({
    moneyGPSAlert: {
      backgroundColor: "#ECEFF1",
    },
    moneyGPSAlertTitle: {
      fontWeight: "bold",
      fontSize: "16px",
    },
    subSummaryColumnHeadind: {
      width: "4rem",
      textAlign: "center",
      fontWeight: "bold",
    },
    subSummaryColumn: {
      width: "4rem",
      textAlign: "center",
      color: "#2162A5",
      fontWeight: "bold",
    },
    subSummaryColumnValue: {
      textTransform: "capitalize",
      fontSize: "24px",
      fontWeight: "bold",
      marginTop: theme.spacing(1),
    },
    tableHeader: {
      fontWeight: "bold",
      backgroundColor: "#FAFAFA",
    },
    checkCircle: {
      fontSize: "28px",
      color: "#2AD000",
    },
    referralCodeTitle: {
      fontWeight: "bold",
    },
    referralCode: {
      fontWeight: "bold",
      color: "#2162A5",
      fontSize: "26px",
    },
    usedReports: {
      color: "#2162A5",
    },
    heading: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    emailHeading: {
      width: "20rem",
      height: "30px",
      fontWeight: "bold",
    },
    email: {
      color: "#2162A5",
      width: "20rem",
      height: "30px",
    },
    statusHeading: {
      width: "10rem",
      height: "30px",
      fontWeight: "bold",
    },
    status: {
      width: "10rem",
      height: "30px",
    },
    input: {
      marginTop: "10px",
      width: "20rem",
      height: "30px",
    },
    noMember: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      fontWeight: "bold",
    },
    errorBox: {
      color: "red",
      height: "20px",
      fontSize: "14px",
    },
    // TODO: Make generic confirm modal dialog and move the styles to the new component:

    // [START] Modal styles
    bglConsentBox: {
      padding: "32px",
      position: "absolute",
      backgroundColor: "#FFFFFF",
      boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
      borderRadius: "5px",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      minHeight: "300px",
      width: "765px",
      maxHeight: "100%",
      // overflowX: "hidden",
      // overflowY: "auto",
    },
    confirmLbl: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px",
      color: "#000000",
      marginBlock: 0,
      marginBottom: 32,
    },
    text14: {
      fontFamily: "Poppins",
      fontStyle: "small",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
    },
    crossIcon: {
      position: "absolute",
      right: "24px",
      cursor: "pointer",
    },
    // [END] Modal styles
  }));
  const classes = useStyles();

  async function fetchData() {
    try {
      setIsLoading(true);
    } catch (e) {
      // TODO: notify user
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    fetchMembers();
  }, [user]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      let response = await members.addMember(email);
      if (response.includes("statusCode=400")) {
        console.log("Status Code is 400");
        setVerifyPrimaryUserModal(false);
        setErrorMessage("Email is already registered!");
        setIsLoading(false);
      } else {
        const parseRes = JSON.parse(response);

        if (parseRes.statusCode === 200) {
          fetchMembers();
          setVerifyPrimaryUserModal(false);
          setModalOpen(false);
          setIsLoading(false);
          setemailId("");
          setErrorMessage("");
        } else {
          setVerifyPrimaryUserModal(false);
          setIsLoading(false);
          setErrorMessage(parseRes.message);
        }
      }
    } catch (error) {
      console.log("Error>>>>>", error);
      setIsLoading(false);
    }
  };

  // Function to fetch members added by main account
  const fetchMembers = async () => {
    setIsLoading(true);

    try {
      let response = await members.getMembers(user.sub);
      let membersList = response.getMemberEmailBySub.items;
      setEmailIds(membersList);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setEmailIds("");
      setIsLoading(false);
    }
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      let response = await members.deleteMember({ id: deleteMail });
      fetchMembers();
      setDeleteModalOpen(false);
      setIsLoading(false);
    } catch (error) {
      console.log("Error>>>>>", error);
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  const sendMail = async () => {
    setIsLoading(true);
    if (email === user.email) {
      setIsLoading(false);
      setErrorMessage("You can't add primary email Id!");
    } else {
      try {
        const sendEmail = await userService.sentVerificationEmail(
          user.email,
          "",
          "send"
        );
        setIsLoading(false);

        if (sendEmail.includes("statusCode=403")) {
          setErrorMessage("Too many failed attempts. Try again later.");
          console.log("Found statusCode=403");
        } else {
          const parsesendEmail = JSON.parse(sendEmail);
          const parseRes = JSON.parse(sendEmail);

          if (parsesendEmail.statusCode === 200) {
            setVerifyPrimaryUserModal(true);
          } else {
            setErrorMessage("Something went wrong!");
          }
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {modalOpen && (
        <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className={classes.bglConsentBox}
            sx={{
              width: `${smallDeviceMatch ? "calc(100% - 32px)" : "638px"}`,
            }}
          >
            {isLoading && <LoadingSpinner />}
            <X
              className={classes.crossIcon}
              onClick={() => {
                setModalOpen(false);
                setemailId("");
                setErrorMessage("");
              }}
            />
            <Grid mt={3}>
              <Typography
                className={classes.confirmLbl}
                style={{ textAlign: "center" }}
              >
                Add New User
              </Typography>
              <Typography
                className={classes.text14}
                style={{ textAlign: "center", marginBottom: "20px" }}
              ></Typography>
              <Typography
                className={classes.text14}
                style={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Enter the email address of the user you wish to grant access to
                receive OTPs for login.
              </Typography>
              <input
                className={classes.input}
                value={email}
                onChange={(e) => {
                  setemailId(e.target.value);
                  setErrorMessage("");
                }}
              />
              <div className={classes.errorBox}>
                {errorMessage.length > 50
                  ? errorMessage.slice(0, 50) + "..."
                  : errorMessage}
              </div>
            </Grid>
            <Grid container alignItems="center" mt={4}>
              <Grid item>
                <CancelButton
                  variant="contained"
                  onClick={() => {
                    setModalOpen(false);
                    setemailId("");
                    setErrorMessage("");
                  }}
                >
                  Cancel
                </CancelButton>
              </Grid>
              <Grid item style={{ marginLeft: "8px" }}>
                <ConfirmSubscriptionCancellationButton
                  variant="contained"
                  disabled={!validateEmail(email)}
                  // onClick={() => handleSubmit()}
                  onClick={() => {
                    sendMail();
                  }}
                >
                  Add
                </ConfirmSubscriptionCancellationButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}

      {verifyPrimaryUserModal && (
        <UserAccessCodeVerificationBase
          modalState={verifyPrimaryUserModal}
          closeModal={() => setVerifyPrimaryUserModal(false)}
          codeVerified={() => handleSubmit()}
        />
      )}

      {deletemodalOpen && (
        <MembercodeVerification
          setModalState={() => setDeleteModalOpen(false)}
          modalState={deletemodalOpen}
          handleSubmit={() => confirmDelete()}
          loading={isLoading}
        />
      )}

      {isLoading ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <div className={classes.heading}>
            <div></div>
            <Button
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <span>Add Email</span>
            </Button>
          </div>

          {emailIds.length === 0 ? (
            <div className={classes.noMember}>
              <span>No Member is added</span>
            </div>
          ) : (
            <>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="10px"
              >
                <Grid item>
                  <div className={classes.subSummaryColumnHeadind}>Sr. No.</div>
                </Grid>
                <Grid item>
                  <div className={classes.emailHeading}>Email Id</div>
                </Grid>
                <Grid className={classes.statusHeading}>
                  <span>Status</span>
                </Grid>
                <Grid className={classes.statusHeading}>
                  <span>Action</span>
                </Grid>
              </Grid>
              {emailIds.map((item, index) => {
                return (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <div className={classes.subSummaryColumn}>
                        {index + 1}
                      </div>
                    </Grid>
                    <Grid item>
                      <div className={classes.email}>
                        {maskEmail(item.email)}
                      </div>
                    </Grid>
                    <Grid
                      className={classes.status}
                      style={{
                        color:
                          item.status === "PENDING" ? "#F3C623 " : "#8BC34A",
                      }}
                    >
                      <span className>{item.status}</span>
                    </Grid>
                    <Grid className={classes.status}>
                      <Button
                        onClick={() => {
                          setDeleteModalOpen(true);
                          setDeleteMail(item.id);
                        }}
                      >
                        <DeleteOutline />
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );
}
