import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Typography, useMediaQuery, Grid } from "@mui/material";
import { X } from "react-feather";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import LoadingSpinner from "../loadingSpinner";

const ConfirmSubscriptionCancellationButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

const CancelButton = styled(Button)({
  height: "44px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  background: "#FFFFFF",
  border: "1px solid #1A2842",
  boxSizing: "border-box",
  color: "#1A2842",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#FFFFFF",
  },
  "&:focus": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});

const useStyles = makeStyles(() => ({
  codeVerificationBox: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "auto",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
    // justifyContent: "center",
  },
  codeVerificationLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
    textAlign: "center",
  },

  text: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    top: "24px",
    cursor: "pointer",
  },

  errorBox: {
    marginTop: "-20px",
    color: "red",
    height: "20px",
    fontSize: "14px",
  },
}));

export default function DeleteMember(props) {
  const classes = useStyles();

  const smallDeviceMatch = !useMediaQuery("(min-width:380px)");

  const [errorMessage, setErrorMessage] = React.useState("");

  const onClose = () => {
    props.setModalState();
  };

  return (
    <>
      <Modal
        open={props.modalState}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={classes.codeVerificationBox}
          style={{ height: 288 }}
          sx={{ padding: `${smallDeviceMatch ? "16px" : "32px"}` }}
        >
          {props.loading && <LoadingSpinner />}
          <div style={{ marginTop: "30px" }}>
            <Typography className={classes.codeVerificationLbl}>
              Delete User
            </Typography>
          </div>
          <div className={classes.errorBox}>
            {errorMessage.length > 50
              ? errorMessage.slice(0, 50) + "..."
              : errorMessage}
          </div>
          <Box
            style={{
              alignSelf: "center",
              marginTop: 16,
              textAlign: "center",
            }}
          >
            <span className={classes.text}>
              Are you sure you want to remove this user?
              <br />
              Once removed, this user will no longer receive OTPs for logging
              in.
            </span>
          </Box>
          <Grid container alignItems="center" justifyContent="center" mt={4}>
            <Grid item>
              <CancelButton
                variant="contained"
                onClick={() => props.setModalState()}
              >
                No
              </CancelButton>
            </Grid>
            <Grid item style={{ marginLeft: "8px" }}>
              <ConfirmSubscriptionCancellationButton
                variant="contained"
                // style={{ marginRight: "8px" }}
                onClick={() => props.handleSubmit()}
              >
                Yes
              </ConfirmSubscriptionCancellationButton>
            </Grid>
          </Grid>
          <X className={classes.crossIcon} onClick={onClose} />
        </Box>
      </Modal>
    </>
  );
}
