import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { InputBase, Typography, useMediaQuery } from "@mui/material";
import Link from "@mui/material/Link";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { headerActions } from "../../store/header";
import { authActions } from "../../store/auth";
import { useHistory } from "react-router-dom";
import userService from "../../services/user";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import LoadingSpinner from "../loadingSpinner";

const ResendButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  // padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

const useStyles = makeStyles((theme) => ({
  codeVerificationBox: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "auto",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
    justifyContent: "center",
  },
  codeVerificationLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    top: "24px",
    cursor: "pointer",
  },
  inputBorder: {
    border: "1px solid red",
  },
  red: {
    color: "red",
    textAlign: "center",
  },
  errorBox: {
    marginTop: "-20px",
    color: "red",
    height: "20px",
    fontSize: "14px",
  },
}));

export default function UserAccessCodeVerificationBase(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const showCodeErrorLimit = useSelector(
    (state) => state.header.showCodeErrorLimit
  );
  const user = useSelector((state) => state.auth.user);
  const email = user?.email;
  const smallDeviceMatch = !useMediaQuery("(min-width:380px)");

  const [digits, setDigits] = React.useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
    digit5: "",
    digit6: "",
  });
  const [wrongCode, setWrongCode] = React.useState(false);
  const [verifying, setVerifying] = React.useState(false);

  const [showLimitError, setShowLimitError] = React.useState(
    showCodeErrorLimit || false
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [minutes, setMinutes] = React.useState(1);
  const [seconds, setSeconds] = React.useState(30);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const resetFields = () => {
    setDigits({
      digit1: "",
      digit2: "",
      digit3: "",
      digit4: "",
      digit5: "",
      digit6: "",
    });
    setWrongCode(false);
    setVerifying(false);
  };

  const onResend = async () => {
    try {
      sendMail();
      setMinutes(5);
      setSeconds(0);
    } catch (error) {
      if (error?.name === "LimitExceededException") {
        setShowLimitError(true);
      }
    }
  };

  const onContact = () => {
    history.push("/contact");
  };

  const handleDigitChange = (event) => {
    if (event.target) {
      const { maxLength, value, name } = event.target;
      const [fieldName, fieldIndex] = name.split("-");
      if (value.length >= maxLength) {
        if (fieldIndex && parseInt(fieldIndex, 10) < 6) {
          const nextSibling = document.querySelector(
            `input[name=digit-${parseInt(fieldIndex, 10) + 1}]`
          );

          if (nextSibling !== null) {
            nextSibling.focus();
            nextSibling.select();
          }
        }
      }

      setDigits({
        ...digits,
        [`digit${fieldIndex}`]: value,
      });
    }
  };
  const onKeyUp = (e) => {
    if (e.keyCode === 8) {
      const { name } = e.target;
      const [fieldName, fieldIndex] = name.split("-");
      if (fieldIndex && parseInt(fieldIndex, 10) > 0) {
        const nextSibling = document.querySelector(
          `input[name=digit-${parseInt(fieldIndex, 10) - 1}]`
        );

        if (nextSibling !== null) {
          nextSibling.focus();
          nextSibling.select();
        }
      }
    }
  };

  React.useEffect(() => {
    verifyCode();
  }, [digits]);

  const verifyCode = async () => {
    const isFilled = Object.values(digits).every((value) => {
      if (value === null || value === undefined || value === "") {
        return false;
      }
      return true;
    });
    if (isFilled) {
      let code = "";
      for (let digit in digits) {
        code += digits[digit];
      }
      let verifyCode = null;
      let verifyTimeout;
      clearTimeout(verifyTimeout);
      verifyTimeout = setTimeout(() => {
        if (verifyCode === null) {
          setVerifying(true);
        }
      }, 3000);
      try {
        setIsLoading(true);
        // Function to Verify MFA for login
        const sendEmail = await userService.sentVerificationEmail(
          email,
          code,
          "verify",
          ""
        );

        if (sendEmail.includes("statusCode=403")) {
          setErrorMessage("Too many failed attempts. Try again later.");
          setIsLoading(false);
          setVerifying(false);
        } else {
          const parseRes = JSON.parse(sendEmail);

          if (parseRes.statusCode === 400) {
            setIsLoading(false);
            setWrongCode(true);
          } else if (parseRes.statusCode === 200) {
            props.codeVerified();
            setIsLoading(false);
          }
        }
      } catch (e) {
        console.log("eeeeeeeeeee....", e);
        // setErrorMessage(e);
        setWrongCode(true);
        clearTimeout(verifyTimeout);
        setVerifying(false);
        setIsLoading(false);
      }
    } else {
      setWrongCode(false);
      setVerifying(false);
      setIsLoading(false);
    }
  };

  const sendMail = async () => {
    setIsLoading(true);
    try {
      const sendEmail = await userService.sentVerificationEmail(
        email,
        "",
        "send",
        ""
      );
      setIsLoading(false);

      if (sendEmail.includes("statusCode=403")) {
        setErrorMessage("Too many failed attempts. Try again later.");
        console.log("Found statusCode=403");
      } else {
        const parsesendEmail = JSON.parse(sendEmail);
        const parseRes = JSON.parse(sendEmail);

        if (parsesendEmail.statusCode === 200) {
          dispatch(
            headerActions.handleVerificationToken({
              verificationToken: parseRes.body.verificationToken,
            })
          );
        } else {
          setErrorMessage("Something went wrong!");
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    resetFields();
  }, [user]);

  const onClose = async () => {
    props.closeModal();
  };

  return (
    <>
      <Modal
        open={props.modalState}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={classes.codeVerificationBox}
          style={{ height: wrongCode ? 328 : 288 }}
          sx={{ padding: `${smallDeviceMatch ? "16px" : "32px"}` }}
        >
          {isLoading && <LoadingSpinner />}
          <Typography className={classes.codeVerificationLbl}>
            Code Verification
          </Typography>
          {verifying && (
            <Box style={{ alignSelf: "center", marginTop: 96 }}>
              {/* <Typography className={classes.text14}>Verifying...</Typography> */}
            </Box>
          )}
          {!verifying && (
            <Typography
              className={classes.text14}
              style={{
                marginBlock: 0,
                marginBottom: 24,
                marginTop: 32,
                textAlign: "center",
              }}
            >
              Enter 6-digits code sent to <strong>{email}</strong>
            </Typography>
          )}
          {!verifying && (
            <Box
              style={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "row",
              }}
              sx={{
                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                  {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                "input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                "& input": {
                  textAlign: "center",
                },
              }}
            >
              <InputBase
                className={`${classes.textField} ${classes.text14} ${
                  wrongCode ? classes.inputBorder : "#ECEFF1"
                }`}
                type="number"
                inputProps={{ maxLength: 1, min: 0, max: 9 }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 1);
                }}
                name={"digit-1"}
                style={{
                  marginRight: "8px",
                  width: "41px",
                }}
                onChange={handleDigitChange}
                value={digits.digit1}
                onKeyUp={onKeyUp}
              />
              <InputBase
                className={`${classes.textField} ${classes.text14} ${
                  wrongCode ? classes.inputBorder : "#ECEFF1"
                }`}
                type="number"
                inputProps={{ maxLength: 1, min: 0, max: 9 }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 1);
                }}
                name={"digit-2"}
                style={{
                  marginRight: "8px",
                  width: "41px",
                }}
                onChange={handleDigitChange}
                value={digits.digit2}
                onKeyUp={onKeyUp}
              />
              <InputBase
                className={`${classes.textField} ${classes.text14} ${
                  wrongCode ? classes.inputBorder : "#ECEFF1"
                }`}
                type="number"
                inputProps={{ maxLength: 1, min: 0, max: 9 }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 1);
                }}
                name={"digit-3"}
                style={{
                  marginRight: "8px",
                  width: "41px",
                }}
                onChange={handleDigitChange}
                value={digits.digit3}
                onKeyUp={onKeyUp}
              />
              <InputBase
                className={`${classes.textField} ${classes.text14} ${
                  wrongCode ? classes.inputBorder : "#ECEFF1"
                }`}
                type="number"
                inputProps={{ maxLength: 1, min: 0, max: 9 }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 1);
                }}
                name={"digit-4"}
                style={{
                  marginRight: "8px",
                  width: "41px",
                }}
                onChange={handleDigitChange}
                value={digits.digit4}
                onKeyUp={onKeyUp}
              />
              <InputBase
                className={`${classes.textField} ${classes.text14} ${
                  wrongCode ? classes.inputBorder : "#ECEFF1"
                }`}
                type="number"
                inputProps={{ maxLength: 1, min: 0, max: 9 }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 1);
                }}
                name={"digit-5"}
                style={{
                  marginRight: "8px",
                  width: "41px",
                }}
                onChange={handleDigitChange}
                value={digits.digit5}
                onKeyUp={onKeyUp}
              />
              <InputBase
                className={`${classes.textField} ${classes.text14} ${
                  wrongCode ? classes.inputBorder : "#ECEFF1"
                }`}
                type="number"
                inputProps={{ maxLength: 1, min: 0, max: 9 }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 1);
                }}
                name={"digit-6"}
                style={{
                  width: "41px",
                }}
                onChange={handleDigitChange}
                value={digits.digit6}
                onKeyUp={onKeyUp}
              />
            </Box>
          )}
          {!verifying && (
            <>
              <Box
                style={{
                  alignSelf: "center",
                  marginTop: 16,
                  textAlign: "center",
                }}
              >
                <span className={classes.text14}>
                  Didn't receive the code? Check your spam/junk folder or{" "}
                </span>
                {/* <Link
                underline="always"
                color="inherit"
                className={classes.text14}
                style={{ cursor: "pointer", color: "black" }}
                onClick={onResend}
              >
                <strong>{"Resend Code"}</strong>
              </Link> */}
                <ResendButton
                  variant="contained"
                  disabled={seconds > 0 || minutes > 0}
                  type="button"
                  style={{ marginLeft: 5 }}
                  onClick={onResend}
                >
                  Resend Code
                </ResendButton>

                {showLimitError && (
                  <Box className={[classes.text14, classes.red].join(" ")}>
                    You have exceeded the Resend Code limit. Try again in 1 hour
                  </Box>
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <span>
                  <strong>
                    <p>
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  </strong>
                </span>
              </Box>
            </>
          )}

          <div className={classes.errorBox}>
            {errorMessage.length > 50
              ? errorMessage.slice(0, 50) + "..."
              : errorMessage}
          </div>

          {wrongCode && !verifying && (
            <Box style={{ position: "fixed", right: 32, bottom: 32 }}>
              <span className={classes.text14}>Please </span>
              <Link
                underline="always"
                color="inherit"
                className={classes.text14}
                style={{ cursor: "pointer", color: "black" }}
                onClick={onContact}
              >
                <strong>{"Contact Us"}</strong>
              </Link>
              <span className={classes.text14}>
                {" "}
                for further discussion on this issue
              </span>
            </Box>
          )}
          <X className={classes.crossIcon} onClick={onClose} />
        </Box>
      </Modal>
    </>
  );
}
