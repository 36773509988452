import * as React from "react";
import { Box, Typography, Grid } from "@mui/material";
import "./index.css";
import { useState } from "react";
import LoadingSpinner from "../loadingSpinner";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import members from "../../services/membersList";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "24px",
    color: "#000000",
    marginLeft: "-200px",
  },
  errorBox: {
    marginTop: "-20px",
    color: "red",
    height: "20px",
    fontSize: "14px",
  },
  main: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ConfirmSubscriptionCancellationButton = styled(Button)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

export default function VerifyEmail() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [verifying, setIsVerifying] = useState(false);
  const [verified, setIsVerified] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const url = window.location.href;
  const urlObj = new URL(url);
  const history = useHistory();
  // Extract the 'verify-token' parameter
  const verifyToken = urlObj.searchParams.get("verify-token");

  // Extract the 'sub' parameter and remove any trailing `.`
  let sub = urlObj.searchParams.get("sub");

  useEffect(() => {
    if (verifyToken && sub) fetchMembers();
  }, []);

  const goToHomePage = () => {
    history.push("/");
  };

  // Function to verify members
  const fetchMembers = async () => {
    setIsLoading(true);
    setIsVerifying(true);
    setIsVerified(false);
    setError(false);
    try {
      let response = null;
      // Call verify member email endpoint
      const user = await Auth.currentUserInfo();
      if (user && user.attributes && user.attributes.sub) {
        response = await members.verifyMemberForLogIn(verifyToken, sub);
      } else {
        response = await members.verifyMember(verifyToken, sub);
      }
      const parseRes = JSON.parse(response);
      if (parseRes.statusCode === 200) {
        setIsLoading(false);
        setIsVerifying(false);
        setIsVerified(true);
      } else {
        setIsLoading(false);
        setIsVerifying(false);
        setIsVerified(false);
        setError(true);
      }
    } catch (e) {
      console.log(e);
      setError(true);
      setIsVerifying(false);
      setIsVerified(false);
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.main}>
      <Box>
        {verifying && (
          <Box>
            <div>
              <LoadingSpinner />
              <Typography className={classes.text}>Verifying...</Typography>
            </div>
          </Box>
        )}
        {verified && (
          <Grid item sx={{ width: 466, mt: 2 }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#8BC34A",
              }}
            >
              Your account is verified, Please go to home page and login.
            </Typography>
            <Grid container alignItems="center" justifyContent="center" mt={4}>
              <Grid item>
                <ConfirmSubscriptionCancellationButton
                  variant="contained"
                  onClick={goToHomePage}
                >
                  Home
                </ConfirmSubscriptionCancellationButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        <div className={classes.errorBox}>
          {error && (
            <span>Something went wrong please try again after some time</span>
          )}
        </div>
      </Box>
    </div>
  );
}
