import { API, Auth } from "aws-amplify";
import { getMemberEmailBySub } from "../graphql/queries";
import {
  deleteMemberEmail,
  inviteMember,
  memberVerify,
} from "../graphql/mutations";

const members = {
  getMembers: async (userData) => {
    const res = await API.graphql({
      query: getMemberEmailBySub,
      variables: {
        sub: userData,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return res.data;
  },

  addMember: async (userData) => {
    const res = await API.graphql({
      query: inviteMember,
      variables: {
        email: userData,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return res.data.inviteMember;
  },
  deleteMember: async (userData) => {
    const res = await API.graphql({
      query: deleteMemberEmail,
      variables: {
        input: userData,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return res.data;
  },
  verifyMember: async (verifyToken, sub) => {
    const response = await API.graphql({
      query: memberVerify,
      variables: {
        data: JSON.stringify({
          token: verifyToken,
          sub: sub,
        }),
      },
      authMode: "AWS_IAM",
    });
    return response.data.memberVerify;
  },
  verifyMemberForLogIn: async (verifyToken, sub) => {
    const response = await API.graphql({
      query: memberVerify,
      variables: {
        data: JSON.stringify({
          token: verifyToken,
          sub: sub,
        }),
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return response.data.memberVerify;
  },
};

export default members;
