import * as React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Typography, Grid, Radio } from "@mui/material";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import userService from "../../services/user";
import { useState } from "react";
import { headerActions } from "../../store/header";
import LoadingSpinner from "../loadingSpinner";
import { useEffect } from "react";
import members from "../../services/membersList";
import { maskEmail } from "../../common/common";
import { Auth } from "aws-amplify";
import { authActions } from "../../store/auth";

// const emailList = [
//   { id: 1, email: "test1@gmail.com" },
//   { id: 2, email: "test2@gmail.com" },
//   { id: 3, email: "test3@gmail.com" },
//   { id: 4, email: "test1@gmail.com" },
//   { id: 5, email: "test2@gmail.com" },
//   { id: 6, email: "test3@gmail.com" },
//   { id: 7, email: "test1@gmail.com" },
//   { id: 8, email: "test2@gmail.com" },
//   { id: 9, email: "test3@gmail.com" },
//   { id: 10, email: "shaurya.bhatnagar+208@fexle.com" },
// ];

const YesButton = styled(Button)({
  minWidth: "184px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "14px",
  padding: "10px 20px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  backgroundColor: "#1A2842",
  color: "white",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#1A2842",
  },
  "&:focus": {
    backgroundColor: "#1A2842",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "#8C94A0;",
  },
});

const NoButton = styled(Button)({
  minWidth: "184px",
  height: "44px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  background: "#FFFFFF",
  border: "1px solid #1A2842",
  boxSizing: "border-box",
  color: "#1A2842",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "&:active": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#FFFFFF",
  },
  "&:focus": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});

const useStyles = makeStyles((theme) => ({
  areYouSureBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "32px",
    position: "absolute",
    width: "765px",
    height: "350px",
    backgroundColor: "#FFFFFF",
    // boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  areYouSureLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
  },
  errorBox: {
    // marginTop: "-20px",
    color: "red",
    height: "20px",
    fontSize: "14px",
  },
}));

export default function EmailListModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.header.user);

  const [emailList, setEmailList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedemail, setselectedemail] = useState("");
  const open = useSelector((state) => state.header.openEmailListBase);
  const { email, password } = useSelector((state) => state.header);

  const onSubmit = async () => {
    await sendMail(email);
  };

  const sendMail = async () => {
    setIsLoading(true);
    const headerState = {
      emailVerificationBase: true,
      openEmailVerificationBase: true,
    };
    try {
      // Send MFA code to login email ID
      const sendEmail = await userService.sentVerificationEmail(
        email,
        "",
        "send",
        selectedemail
      );
      setIsLoading(false);
      if (sendEmail.includes("statusCode=403")) {
        setErrorMessage("Too many failed attempts. Try again later.");
        setIsLoading(false);
        console.log("Found statusCode=403");
      } else {
        const parsesendEmail = JSON.parse(sendEmail);
        setIsLoading(false);
        const parseRes = JSON.parse(sendEmail);

        if (parsesendEmail.statusCode === 200) {
          dispatch(
            headerActions.handleVerificationToken({
              verificationToken: parseRes.body.verificationToken,
            })
          );
          dispatch(headerActions.handleEmailVerificationBase(headerState));
          dispatch(headerActions.handleCloseEmailListBase());
          localStorage.setItem("tempemail", selectedemail);
          localStorage.setItem("primEmail", email);
          localStorage.setItem("verified", "NO");
        } else {
          dispatch(headerActions.handleOpenTryAgain());
          dispatch(headerActions.handleCloseEmailListBase());
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const close = async () => {
    dispatch(headerActions.handleCloseEmailListBase());

    const headerState = {
      emailVerificationBase: false,
      email: "",
      password: "",
      openEmailVerificationBase: false,
      showCodeErrorLimit: false,
    };
    localStorage.removeItem("selectedProduct");
    await Auth.signOut();
    localStorage.setItem("verified", "NO");
    dispatch(authActions.handleUserAuth({ loggedIn: false, user: null }));
    dispatch(headerActions.handleSelectedFund({}));
    dispatch(headerActions.handleFirmDetails({}));
    dispatch(headerActions.setSelectedProduct({ selectedProduct: undefined }));
    dispatch(
      headerActions.handlePanelState({
        profileMenu: false,
        shopping: false,
        notification: false,
      })
    );
    dispatch(headerActions.handleEmailVerificationBase(headerState));
    dispatch(headerActions.handleCloseEmailVerificationBase());
  };

  useEffect(() => {
    if (user.sub) fetchMembers();
  }, [user]);

  // Function to fetch members added by main account
  const fetchMembers = async () => {
    setIsLoading(true);
    try {
      let response = await members.getMembers(user?.sub);
      let membersList = response.getMemberEmailBySub.items.filter(
        (e) => e.status === "VERIFIED"
      );
      membersList.unshift({ email });
      setEmailList(membersList);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setErrorMessage("Something went wrong, please try after some time.");
      setselectedemail("");
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {isLoading && <LoadingSpinner />}
        <div
          className={classes.areYouSureBox}
          style={{ textAlign: "center", height: "386px" }}
        >
          <Grid
            style={{ display: "flex", textAlign: "center", marginTop: "30px" }}
          >
            <Typography variant="smaller" className="profileHeading">
              Please select the email address where you would like to receive
              the OTP to log in.
            </Typography>
          </Grid>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              height: "150px",
              marginTop: "20px",
              marginBottom: "20px",
              overflowY: "auto",
              width: "100%",
            }}
          >
            {emailList.map((email, index) => {
              return (
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // justifyContent: "space-between",
                    padding: "3px",
                  }}
                  key={index}
                >
                  <Radio
                    color="primary"
                    value={email.email}
                    checked={selectedemail === email.email}
                    onChange={(e) => {
                      setselectedemail(e.target.value);
                    }}
                  />
                  <span>{maskEmail(email.email)}</span>
                </Grid>
              );
            })}
          </Grid>

          <Grid style={{ display: "flex", alignSelf: "center" }}>
            <YesButton
              onClick={onSubmit}
              disabled={selectedemail === ""}
              style={{ marginBottom: 16, marginRight: 10, minWidth: "110px" }}
            >
              Send
            </YesButton>
            <NoButton onClick={close} style={{ minWidth: "110px" }}>
              Close
            </NoButton>
          </Grid>
          <X className={classes.crossIcon} onClick={close} />
          <div className={classes.errorBox}>
            {errorMessage.length > 50
              ? errorMessage.slice(0, 50) + "..."
              : errorMessage}
          </div>
        </div>
      </>
    </Modal>
  );
}
