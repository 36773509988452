export const getExtraReportLink = (sub, email) =>
  `${process.env.REACT_APP_STRIPE_REPORT_PAYMENT_LINK}?client_reference_id=survey_id-SMSF001E--sub-${sub}&locale=en&__embed_source=${process.env.REACT_APP_STRIPE_REPORT_BUY_BUTTON_ID}&__customer_email=${email}`;

export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  console.log("emailRegex.test(email)", emailRegex.test(email));
  return emailRegex.test(email);
};

export function maskEmail(email) {
  if (email && validateEmail(email)) {
    // const [localPart, domain] = email.split("@");
    // const domainParts = domain.split(".");
    // // Mask the local part
    // const maskedLocalPart =
    //   localPart.length > 2
    //     ? localPart[0] + localPart[1] + "*".repeat(localPart.length - 2)
    //     : "*".repeat(localPart.length);
    // // Mask the domain
    // const maskedDomainName =
    //   domainParts[0].length > 2
    //     ? domainParts[0][0] +
    //       domainParts[0][1] +
    //       "*".repeat(domainParts[0].length - 2)
    //     : "*".repeat(domainParts[0].length);
    // const domainExtension = domainParts[1];
    // return `${maskedLocalPart}@${maskedDomainName}.${domainExtension}`;

    const [localPart, domain] = email.split("@");
    const domainParts = domain.split(".");

    // Mask the local part
    let maskedLocalPart;
    if (localPart.length > 2) {
      maskedLocalPart =
        localPart[0] +
        "*".repeat(localPart.length - 2) +
        localPart[localPart.length - 1];
    } else if (localPart.length === 2) {
      maskedLocalPart = localPart[0] + "*";
    } else {
      maskedLocalPart = "*";
    }

    // Mask the domain name
    const domainName = domainParts[0];
    const maskedDomainName =
      domainName.length > 2
        ? domainName[0] +
          "*".repeat(domainName.length - 2) +
          domainName[domainName.length - 1]
        : domainName[0] + "*";

    // Combine masked parts with the domain extension
    const domainExtension = domainParts[1];
    return `${maskedLocalPart}@${maskedDomainName}.${domainExtension}`;
  } else {
    return email;
  }
}
