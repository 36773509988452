import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { InputBase, Typography, useMediaQuery } from "@mui/material";
import Link from "@mui/material/Link";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { headerActions } from "../../store/header";
import { authActions } from "../../store/auth";
import { useHistory } from "react-router-dom";
import userService from "../../services/user";
import { bglOperations } from "../../services/constants";
import bglService from "../../services/bgl";
import * as common from "../common";

const useStyles = makeStyles((theme) => ({
  codeVerificationBox: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "auto",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
    justifyContent: "center",
  },
  codeVerificationLbl: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000000",
    marginBlock: 0,
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#ECEFF1",
    alignSelf: "stretch",
    height: "44px",
    paddingLeft: 8,
    paddingRight: 8,
  },
  text14: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
  },
  crossIcon: {
    position: "absolute",
    right: "24px",
    top: "24px",
    cursor: "pointer",
  },
  inputBorder: {
    border: "1px solid red",
  },
  red: {
    color: "red",
    textAlign: "center",
  },
}));

export default function CodeVerificationBase({ handlePasswordUpdated }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const open = useSelector((state) => state.header.openCodeVerificationBase);

  const email = useSelector((state) => state.header.email);
  const password = useSelector((state) => state.header.password);
  const resetPassword = useSelector((state) => state.header.resetPassword);
  const showCodeErrorLimit = useSelector(
    (state) => state.header.showCodeErrorLimit
  );

  const userData =
    common.getCookie("userData") && common.getCookie("userData") !== undefined
      ? JSON.parse(common.getCookie("userData"))
      : "";
  const smallDeviceMatch = !useMediaQuery("(min-width:380px)");

  //const newPassword = useSelector(state => state.header.newPassword);
  const [digits, setDigits] = React.useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
    digit5: "",
    digit6: "",
  });
  const [wrongCode, setWrongCode] = React.useState(false);
  const [verifying, setVerifying] = React.useState(false);
  const [showLimitError, setShowLimitError] = React.useState(
    showCodeErrorLimit || false
  );

  const resetFields = () => {
    setDigits({
      digit1: "",
      digit2: "",
      digit3: "",
      digit4: "",
      digit5: "",
      digit6: "",
    });
    setWrongCode(false);
    setVerifying(false);
  };

  const onResend = async () => {
    try {
      if (!resetPassword) {
        const code = await Auth.resendSignUp(email);
      } else {
        await Auth.forgotPassword(email);
      }
    } catch (error) {
      if (error?.name === "LimitExceededException") {
        setShowLimitError(true);
      }
    }
  };

  const onContact = () => {
    history.push("/contact");
  };

  const handleDigitChange = (event) => {
    if (event.target) {
      const { maxLength, value, name } = event.target;
      const [fieldName, fieldIndex] = name.split("-");
      if (value.length >= maxLength) {
        if (fieldIndex && parseInt(fieldIndex, 10) < 6) {
          const nextSibling = document.querySelector(
            `input[name=digit-${parseInt(fieldIndex, 10) + 1}]`
          );

          if (nextSibling !== null) {
            nextSibling.focus();
            nextSibling.select();
          }
        }
      }

      setDigits({
        ...digits,
        [`digit${fieldIndex}`]: value,
      });
    }
  };
  const onKeyUp = (e) => {
    if (e.keyCode === 8) {
      const { name } = e.target;
      const [fieldName, fieldIndex] = name.split("-");
      if (fieldIndex && parseInt(fieldIndex, 10) > 0) {
        const nextSibling = document.querySelector(
          `input[name=digit-${parseInt(fieldIndex, 10) - 1}]`
        );

        if (nextSibling !== null) {
          nextSibling.focus();
          nextSibling.select();
        }
      }
    }
  };

  React.useEffect(async () => {
    const isFilled = Object.values(digits).every((value) => {
      if (value === null || value === undefined || value === "") {
        return false;
      }
      return true;
    });
    if (isFilled) {
      let code = "";
      for (let digit in digits) {
        code += digits[digit];
      }
      let verifyCode = null;
      let verifyTimeout;
      clearTimeout(verifyTimeout);
      verifyTimeout = setTimeout(() => {
        if (verifyCode === null) {
          setVerifying(true);
        }
      }, 3000);
      try {
        if (!resetPassword) {
          const verifyCode = await Auth.confirmSignUp(email, code);
          localStorage.setItem("verified", "YES");
          dispatch(headerActions.handleCloseCodeVerificationBase());
          setVerifying(false);
          dispatch(
            headerActions.handleCodeLimitError({
              showCodeErrorLimit: false,
            })
          );
          setShowLimitError(false);
          dispatch(headerActions.handleOpenFirmDetails());
        } else {
          verifyCode = await Auth.forgotPasswordSubmit(email, code, password);
          dispatch(headerActions.handleCloseCodeVerificationBase());
          dispatch(headerActions.handleOpenPasswordUpdatedModal());
          const user = await Auth.signIn(email, password);
          localStorage.setItem("verified", "YES");
          const userInfo = await userService.getUser(user.attributes.sub);
          const userObj = await userService.getUserFlatObject(userInfo);

          dispatch(
            authActions.handleUserAuth({
              loggedIn: false,
              user: JSON.stringify(userObj),
            })
          );
          setVerifying(false);
        }
      } catch (e) {
        setWrongCode(true);
        clearTimeout(verifyTimeout);
        setVerifying(false);
      }

      // setVerifying(true);
      // Need to call amplify to check if the code is wrong or not here before setting wrongCode to true
      // Depends on checking result,
      // - if failed then set wrongCode to true
      // - if verification is successful, set verifying to false, then call headerActions.handleCloseCodeVerificationBase()
      // setWrongCode(true);

      // dispatch(headerActions.handleWelcome());
    } else {
      setWrongCode(false);
      setVerifying(false);
    }
  }, [digits]);

  React.useEffect(() => {
    resetFields();
  }, []);

  const onClose = () => {
    dispatch(headerActions.handleCloseCodeVerificationBase());
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={classes.codeVerificationBox}
        style={{ height: wrongCode ? 328 : 288 }}
        sx={{ padding: `${smallDeviceMatch ? "16px" : "32px"}` }}
      >
        <Typography className={classes.codeVerificationLbl}>
          Code Verification
        </Typography>
        {verifying && (
          <Box style={{ alignSelf: "center", marginTop: 96 }}>
            <Typography className={classes.text14}>Verifying...</Typography>
          </Box>
        )}
        {!verifying && (
          <Typography
            className={classes.text14}
            style={{
              marginBlock: 0,
              marginBottom: 24,
              marginTop: 32,
              textAlign: "center",
            }}
          >
            Enter 6-digits code sent to <strong>{email}</strong>
          </Typography>
        )}
        {!verifying && (
          <Box
            style={{
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
            }}
            sx={{
              "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              "input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input": {
                textAlign: "center",
              },
            }}
          >
            <InputBase
              className={`${classes.textField} ${classes.text14} ${
                wrongCode ? classes.inputBorder : "#ECEFF1"
              }`}
              type="number"
              inputProps={{ maxLength: 1, min: 0, max: 9 }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 1);
              }}
              name={"digit-1"}
              style={{
                marginRight: "8px",
                width: "41px",
              }}
              onChange={handleDigitChange}
              value={digits.digit1}
              onKeyUp={onKeyUp}
            />
            <InputBase
              className={`${classes.textField} ${classes.text14} ${
                wrongCode ? classes.inputBorder : "#ECEFF1"
              }`}
              type="number"
              inputProps={{ maxLength: 1, min: 0, max: 9 }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 1);
              }}
              name={"digit-2"}
              style={{
                marginRight: "8px",
                width: "41px",
              }}
              onChange={handleDigitChange}
              value={digits.digit2}
              onKeyUp={onKeyUp}
            />
            <InputBase
              className={`${classes.textField} ${classes.text14} ${
                wrongCode ? classes.inputBorder : "#ECEFF1"
              }`}
              type="number"
              inputProps={{ maxLength: 1, min: 0, max: 9 }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 1);
              }}
              name={"digit-3"}
              style={{
                marginRight: "8px",
                width: "41px",
              }}
              onChange={handleDigitChange}
              value={digits.digit3}
              onKeyUp={onKeyUp}
            />
            <InputBase
              className={`${classes.textField} ${classes.text14} ${
                wrongCode ? classes.inputBorder : "#ECEFF1"
              }`}
              type="number"
              inputProps={{ maxLength: 1, min: 0, max: 9 }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 1);
              }}
              name={"digit-4"}
              style={{
                marginRight: "8px",
                width: "41px",
              }}
              onChange={handleDigitChange}
              value={digits.digit4}
              onKeyUp={onKeyUp}
            />
            <InputBase
              className={`${classes.textField} ${classes.text14} ${
                wrongCode ? classes.inputBorder : "#ECEFF1"
              }`}
              type="number"
              inputProps={{ maxLength: 1, min: 0, max: 9 }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 1);
              }}
              name={"digit-5"}
              style={{
                marginRight: "8px",
                width: "41px",
              }}
              onChange={handleDigitChange}
              value={digits.digit5}
              onKeyUp={onKeyUp}
            />
            <InputBase
              className={`${classes.textField} ${classes.text14} ${
                wrongCode ? classes.inputBorder : "#ECEFF1"
              }`}
              type="number"
              inputProps={{ maxLength: 1, min: 0, max: 9 }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 1);
              }}
              name={"digit-6"}
              style={{
                width: "41px",
              }}
              onChange={handleDigitChange}
              value={digits.digit6}
              onKeyUp={onKeyUp}
            />
          </Box>
        )}
        {!verifying && (
          <Box
            style={{ alignSelf: "center", marginTop: 16, textAlign: "center" }}
          >
            <span className={classes.text14}>
              Didn't receive the code? Check your spam/junk folder or{" "}
            </span>
            <Link
              underline="always"
              color="inherit"
              className={classes.text14}
              style={{ cursor: "pointer", color: "black" }}
              onClick={onResend}
            >
              <strong>{"Resend Code"}</strong>
            </Link>
            {showLimitError && (
              <Box className={[classes.text14, classes.red].join(" ")}>
                You have exceeded the Resend Code limit. Try again in 1 hour
              </Box>
            )}
          </Box>
        )}
        {wrongCode && !verifying && (
          <Box style={{ position: "fixed", right: 32, bottom: 32 }}>
            <span className={classes.text14}>Please </span>
            <Link
              underline="always"
              color="inherit"
              className={classes.text14}
              style={{ cursor: "pointer", color: "black" }}
              onClick={onContact}
            >
              <strong>{"Contact Us"}</strong>
            </Link>
            <span className={classes.text14}>
              {" "}
              for further discussion on this issue
            </span>
          </Box>
        )}
        <X className={classes.crossIcon} onClick={onClose} />
      </Box>
    </Modal>
  );
}
